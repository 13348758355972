import React, { FC, useState } from 'react'
import * as pageUtils from './__details.utils'
import { REQUEST_CONFIGS_KEYS } from '@services/constants'
import { RadioList, SelectList } from '@utils/request'
import { formatStrapiText } from '@utils/methods'
import requestForm from '@components/requestForms/request.form'

const WateringPermitFormDetails: FC<pageUtils.Props> = ({
	pageAssets,
	request
}) => {
	const radioListYesNo: RadioList[] = [
		{
			label: pageAssets.request_form_select_yes,
			value: 'true',
			key: 'request_form_select_yes'
		},
		{
			label: pageAssets.request_form_select_no,
			value: 'false',
			key: 'request_form_select_no'
		}
	]

	const radioListWateringPurpose: RadioList[] = [
		{
			label:
				pageAssets?.request_form_watering_permit_wateringPurpose_firstOption_label,
			value: REQUEST_CONFIGS_KEYS.wateringPermitPurpose.firstOption,
			key: 'request_form_watering_permit_wateringPurpose_firstOption_label'
		},
		{
			label:
				pageAssets?.request_form_watering_permit_isWateringLimit_secondOption_label,
			value: REQUEST_CONFIGS_KEYS.wateringPermitPurpose.secondOption,
			key: 'request_form_watering_permit_isWateringLimit_secondOption_label'
		},
		{
			label:
				pageAssets?.request_form_watering_permit_isWateringLimit_thirdOption_label,
			value: REQUEST_CONFIGS_KEYS.wateringPermitPurpose.thirdOption,
			key: 'request_form_watering_permit_isWateringLimit_thirdOption_label'
		},
		{
			label:
				pageAssets?.request_form_watering_permit_isWateringLimit_fourthOption_label,
			value: REQUEST_CONFIGS_KEYS.wateringPermitPurpose.fourthOption,
			key: 'request_form_watering_permit_isWateringLimit_fourthOption_label'
		}
	]

	const getLabelFromList = (list: SelectList[], value) => {
		return list?.find((element) => element.value == value?.toString())?.label
	}

	const { authUser } = requestForm()

	return (
		<>
			<h4 className={pageUtils.classes.h4} style={{ fontSize: '25px' }}>
				{pageAssets?.request_form_watering_permit}
			</h4>

			<h5 className={pageUtils.classes.h5}>
				{pageAssets?.request_form_location}
			</h5>

			<div>
				<strong>
					{pageAssets?.request_form_wateringPermit_isSameLocation_label}
				</strong>
				<br />
				<p className={pageUtils.classes.paragraph}>
					{getLabelFromList(
						radioListYesNo,
						request.incidentForm?.sameAddressAsApplicant
					)}
				</p>
			</div>

			{request?.incidentForm?.sameAddressAsApplicant?.toString() ===
			radioListYesNo[1].value ? (
				<div>
					<strong>{pageAssets?.request_form_location}</strong>
					<br />
					<p className={pageUtils.classes.paragraph}>
						{request.incidentForm?.addressField}
						<br />
						{request.incidentForm?.addressAppartment}
						<br />
						{request.incidentForm?.addressLotNumber
							? pageAssets?.label_cadastral_number +
							  ': ' +
							  request.incidentForm?.addressLotNumber
							: ''}
					</p>
				</div>
			) : (
				<>
					<strong>{pageAssets?.label_address}</strong>
					<br />
					{authUser?.profile?.address.address}
					<br />
					{authUser?.profile?.address.city}
					{authUser?.profile?.address.state
						? ` (${authUser?.profile?.address.state}) `
						: ' '}
					{authUser?.profile?.address.postalCode}
					<br />
					{authUser?.profile?.address.apartment
						? `${formatStrapiText(pageAssets?.label_apartment_office)} ${
								authUser?.profile?.address.apartment
						  }`
						: ''}
				</>
			)}
			<strong>{pageAssets?.request_form_watering_permit_isOwner_label}</strong>
			<br />
			<p className={pageUtils.classes.paragraph}>
				{getLabelFromList(radioListYesNo, request.incidentForm?.isOwner)}
			</p>

			<div>
				<strong>
					{
						pageAssets?.request_form_fireHydrantsTempAqueductNetwork_startDate_label
					}
				</strong>
				<br />
				<p className={pageUtils.classes.paragraph}>
					{pageUtils.getLocaleDate(request.incidentForm?.startDate!)}
				</p>
			</div>

			<div>
				<strong>
					{
						pageAssets?.request_form_fireHydrantsTempAqueductNetwork_endDate_label
					}
				</strong>
				<br />
				<p className={pageUtils.classes.paragraph}>
					{pageUtils.getLocaleDate(request.incidentForm?.endDate!)}
				</p>
			</div>

			<strong>{pageAssets?.request_form_wateringPurpose_watering_label}</strong>
			<br />
			<p className={pageUtils.classes.paragraph}>
				{getLabelFromList(
					radioListWateringPurpose,
					request.incidentForm?.wateringPurpose
				)}
			</p>
		</>
	)
}

export default WateringPermitFormDetails
